body,
html {
  height: 100%;
}
.parallax {
  /* The image used */
  background-image: url("../images/palletmain.jpg");

  /* Full height */
  height: 100vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.7;
}
.bgimg-2 {
  background-image: url("../images/palletInside.webp");
  min-height: 400px;
  background-repeat: no-repeat;
  background-position: center;
}
.bgimg-4 {
  background-image: url("../images/pallet2.jpg");
  min-height: 400px;
  background-repeat: no-repeat;
  background-position: center;
}
.caption {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
}
.caption span.border {
  background-color: white;
  color: #c2554a;
  padding: 18px;
  font-size: calc(10px + 2vmin);
  letter-spacing: 10px;
}

.pallet:link {
  color: #6da4e3;
}
.pallet:hover {
  color: red;
}
.bgimg-3 {
  background-image: url("../images/palletOutside.jpg");
  min-height: 400px;
  background-repeat: no-repeat;
  background-position: center;
}
.bold {
  color: black;
}

p {
  font-size: 20px;
}

@media only screen and (max-width: 420px) and (min-width: 359px) {
  p {
    font-size: 10px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 360px) {
  p {
    font-size: 15px;
  }
  .pallet {
    font-size: 15px;
  }
}
