.herobox {
  width: "80%";

  minheight: "600px";
  alignitems: "center";
  justifycontent: "center";
}

.ag-theme-alpine .ag-header-cell {
  background-color: #d9d9d9;
  color: black;
}

.ag-theme-alpine .ag-floating-filter-input {
  color: black;
}
button:hover {
  color: #7f9c96;
}
